import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>A Ty gdzie tu !?</h1>
    <p>Ta strona nie istnieje!</p>
  </>
);

export default NotFoundPage;
